body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 800px) {
  .hide-in-mobile {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}

@media screen and (min-width: 801px) {
  .show-in-mobile-only {
    display: none;
  }
}

.online-status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

.online-status-dot.online {
  background-color: green;
}

.online-status-dot.offline {
  border: 2px solid rgb(69, 69, 69);
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-top: 3px;
}
.mobile-no-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.avathar-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  border-radius: 50px;
  margin-right: 5px;
}
.avathar-box-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  margin-right: 5px;
}
.status-chip {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.channel-chip {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.role-dropdown {
  width: 150px;
  border: 1px solid #e7e4e4;
  padding: 4px;
  border-radius: 5px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.manage-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.display-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: 30px;
  padding: 4px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #e7e4e4;
  color: black;
}
.display-attachment {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: 30px;
  padding: 4px;
  border-radius: 5px;
}
.mobile-view-history {
  width: 100%;
}
.date-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 10px;
}
.check-in-out-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
}
.calender-box {
  display: flex;
  flex-direction: row;
  border: 1px solid #e7e4e4;
  width: 300px;
  height: 38px;
  justify-content: space-between;
  border-radius: 4px;
  position: relative;
}
.calender-box-mobile {
  display: flex;
  flex-direction: row;
  border: 1px solid #e7e4e4;
  width: 300px;
  height: 40px;
  justify-content: space-between;
  border-radius: 4px;
  position: relative;
}
.location-modal {
  width: 400px;
  height: 300px;
}
.calender-image {
  display: flex;
  background-color: #75b121;
  width: 39px;
  height: 37px;
  justify-content: center;
  align-items: center;
}
.loc-image {
  display: flex;
  background-color: #75b121;
  width: 33px;
  height: 31px;
  justify-content: center;
  border-radius: 4px;
  align-items: center;
}
.selected-month {
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
}
.selected-month-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px;
}
.custom-popover {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 4px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
}
.month-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  padding: 16px;
}
.month-option {
  cursor: pointer;
  padding: 2px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.month-option.selected {
  background-color: #75b121;
  color: white;
}
.popover-buttons {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}
.popover-buttons button {
  width: 130px;
}
.year-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 30px;
  padding: 10px;
  margin: 10px;
}
.year-navigation button {
  background-color: transparent;
  border: 1px solid #ccc;
  cursor: pointer;
  font-size: 14px;
  color: black;
  height: 18px;
  width: 20px;
  padding: 2px;
}
.year-navigation button:hover {
  background-color: #f7f5f5;
}
.location-modal {
  display: flex;
  width: 100%;
  height: 100%;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.modal {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  padding: 10px;
  position: relative;
}
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0px;
}
.location-not-available {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cbcbcb;
  width: 100%;
  height: 450px;
}
.profile-details {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.profile-item {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 10px;
}
.mobile-rooms {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.mobile-rooms-inside {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.location-btn-mobile {
  display: flex;
  width: 100%;
  margin-top: 5px;
}
.calender-box-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid #ccc;
  cursor: pointer;
  font-size: 14px;
  color: black;
  height: 18px;
  width: 20px;
  padding: 2px;
  font-size: 18px;
}
.calender-box-button:hover {
  background-color: #f7f5f5;
}
.year-month-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  height: 30px;
  padding: 10px;
  margin: 5px;
}
.year-month-navigation button {
  background-color: transparent;
  border: 1px solid #ccc;
  cursor: pointer;
  font-size: 14px;
  color: black;
  height: 18px;
  width: 20px;
  padding: 2px;
}
.year-month-navigation button:hover {
  background-color: #f7f5f5;
}
.attendence-calender {
  display: grid;
  padding: 16px;
}
.attendence-calender-mobile {
  display: grid;
  justify-content: center;
  padding: 5px;
  padding-top: 10px;
}
.day-cell {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.selected {
  background-color: #75b121;
  color: white;
  border-radius: 5px;
}
.calender-new .mantine-Calendar-calendarHeader {
  border-bottom: 1px solid #ccc !important;
}
.calender-new-mobile .mantine-Calendar-calendarHeader {
  border-bottom: 1px solid #ccc !important;
}
.mantine-Calendar-calendarHeaderControl {
  border: 1px solid #ccc;
  width: 15px;
  height: 15px;
}
.attendence-datepicker {
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-bottom: 10px;
}
.month-year-display {
  display: flex;
  margin: 2px;
  justify-content: center;
  align-items: center;
}
.time-zone {
  width: 100%;
}
.time-zone .css-13cymwt-control {
  border: 1px solid #e7e4e4;
}
.qr-scanning {
  display:flex;
  padding: 10px;
  width: auto;
  height: auto;
}
.qr-image {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #75B121;
  width:40px;
  height:40px;
  padding: 5px;
}
.download-details {
  display: flex;
  flex-direction: column;
  width:320px;
  margin-left: 10px;
}
.store {
  display: flex;
  flex-direction: row;
  width:300px;
  justify-content: space-between;
  padding-left: 10px;
}
.workspace-button {
  display: flex;
  background-color: #75B121;
  border-radius: 10px;
  height: 30px;
  align-items: center;
}
.workspace-button:hover {
  background-color: #75B121;
}
.workspace-cards {
  display: flex;
  width: 95%;
  flex-direction: row;
  justify-content: space-between;
  height: 100px;
  align-items: center;
}
.workspace-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  margin-right: 5px;
}
.empty-workspace {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  flex-direction: column;
}
.workspace-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
  width: 50%;
}
.workspace-delete {
  width: 47%;
  height:40px;
  background-color: red;
  color:white;
  border-radius: 8px;
}
.workspace-disable {
  width: 47%;
  height:40px;
  color:white;
  border-radius: 8px;
}
.workspace-delete-mobile {
  width: 100%;
  height:40px;
  background-color: red;
  color:white;
  border-radius: 8px;
  margin-bottom: 10px;
}
.workspace-disable-mobile {
  width: 100%;
  height:40px;
  color:white;
  border-radius: 8px;
  margin-bottom: 10px;
}
.navbar-styling {
  display: flex;
  justify-content: flex-end;
  background-color: red;
}
.mobile-workspace {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.error-message {
  color: red;
  margin-top: 5px;
}
.upgrade-modal .mantine-Modal-modal{
  width: max-content;
  height:max-content;
}
.business-plan-card {
  background-color: #FBFBFB;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.unchecked {
  height: 30px;
  width: 30px;
  padding: 2px;
  border-radius: 50%;
  border: 2px solid #75B121;
  background-color: white;
}
.show-coupons {
  margin-left: 2px;
  margin-right: 5px;
  display: flex;
  justify-content: space-between;
}
.upgrade-button {
  background-color: white;
  border: 1px solid #75B121;
  color: #75B121;
  margin-bottom: 20px;
}
.update-button {
  background-color: white;
  border: 1px solid #005F2B;
  color: #005F2B;
  margin-bottom: 20px;
}


