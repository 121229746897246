.scroll::-webkit-scrollbar {
    display: none;

}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}