.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.invite-users-modal {
  .mantine-Modal-header {
    align-items: flex-start !important;
  }
}

.edit-btn {
  border: 0;
  cursor: pointer;
}

.user-detail-rooms-table {
  th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color:#FCFCFD;
    color: #667085 !important;
  }
  tr {
    color: #667085 !important;
  }
}

.text-center {
  text-align: center;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.avatar-wrapper {
  position: relative;
  .edit-actions-icon,
  .edit-icon {
    position: absolute;
    right: 25px;
    bottom: 15px;
    label {
      cursor: pointer;
    }
  }
  .edit-actions-icon {
    background-color: rgba(255, 255, 255, 0.432);
    backdrop-filter: blur(8px);
    right: 5px;
    display: flex;
    box-shadow: 0 2px 4px rgba(128, 128, 128, 0.358);

    .cancel-icon,
    .submit-icon {
      transition: all 0.2s ease-in-out;

      &:active,
      &:visited,
      &:focus {
        transform: scale(1) !important;
        box-shadow: unset !important;
      }

      &:hover {
        transform: scale(1.05);
        // transform: rotate(360deg);
        box-shadow: 0 2px 5px rgba(128, 128, 128, 0.711);
      }
    }

    .cancel-icon {
      background-color: #fa5252;
      color: white;
    }
    .submit-icon {
      background-color: #2f9e44;
      color: white;
    }
  }
}
