.icon-tabler-player-pause {
    fill: black;
}

.icon-tabler-player-play {
    fill: black;
}

.mantine-DatePicker-input {
    min-height: 36px;
}

.mantine-DatePicker-dropdown {
    scale: 0.8;
}

.mantine-Checkbox-root {
    display: flex;
}

.modalOpen:hover {
    background-color: transparent;
}

.modal-header {
    margin: 0;

    top: 0px;
}

.modal-root {
    background-color: transparent;
}


.image-modal {
    padding: 0;
    background-color: transparent;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 95%;

}

.close-modal {
    position: absolute;
    z-index: 5;
    overflow: hidden;
    top: 10px;
    scale: 1.5;
    right: 10px;

}

.close-modal:hover {
    background-color: transparent;
}

.modal-inner {
    margin: 0;
    padding: 0;
}

.modal-body {
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100%;

}